import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription, firstValueFrom } from "rxjs";
import { SharedService } from "../../services/shared/shared.service";
import { LayersService } from "../../services/layers/layers.service";
import { DxDeferRenderingComponent, DxPopupModule, DxTemplateModule } from 'devextreme-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { LayerDataAttribute } from '../../models/layers/layer-data-attribute.models';
import { EsriService } from "../../services/esri/js-esri.service";
import { BaseComponent } from "../basecomponent/base.component";
import { SketchViewService } from "../../services/customlayer/sketch-view.service";
import notify from 'devextreme/ui/notify';
import { AzureStorageService } from '../../services/azurestorage/azure-storage.service';
import { DocumentUploadComponent } from '../documentupload/document.upload.component';
import { UserService } from '../../services/user/user.service';
import { LayerServerService } from '../../services/layers/layer-server.service';
import { CoordinateItem, MapConfig } from '../../models/map/map-config';
import { FilterAttributeService } from '../../services/filterattribute/filter-attribute.service';
import { AttributeSourceItem, LayerDataItem, LayerDataWithAttr } from '../../models/layers/layer.data.model';
import { ConfigService } from '../../services/shared/utils/config.service';
import { MapService } from '../../services/map/map.service';
import { LegendItem } from '../../models/layers/legend.model';
import { LegendQuerySevice } from '../../services/legends/legend-query.service';
import { SquareUnits } from '../../models/draw/square-units.model';
import validationEngine from 'devextreme/ui/validation_engine';

@Component({
  selector: 'app-edit-geodata',
  templateUrl: './edit-geodata.component.html',
  styleUrls: ['./edit-geodata.component.scss']
})
export class EditGeodataComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
  attributes: LayerDataItem[];
  layerAttribute: LayerDataAttribute[];
  featureAttr: any;
  list: any[];
 // mapImageLayer: __esri.MapImageLayer;
  layerName: any;
  //subscription: Subscription;
  popupVisible: boolean = false;
  private selectedFeature: any;// __esri.Graphic;
  cloneFeatureLayer: __esri.FeatureLayer;
  isCustomLayer: boolean = false;
  isNavigationLayer: boolean = false;
  CurrentColor: any;
  currentStyle: any;
  currentWidth: number = 2;
  currentType: string;
  styleDataSource: any[] = [];
  listDocuments: any[];
  //layerTypes: any[] = [];
  // layerTypeID: any;
  documentContainerName: string = "test";
  maxAmountFiles: number;
  isMaxFiles: boolean = false;
  currentSR: any = 'WGS';
  sourceCoordinates: CoordinateItem[] = [];
    //[{ index: "0", X: 28.627116215595898, Y: 50.327732579756436 }, { index: "1", X: 28.626738923176568, Y: 50.32827164816774 }
    //, { index: "2", X: 28.627718086836257, Y: 50.328369138610434 }, { index: "3", X: 28.627116215595898, Y: 50.327732579756436 }]

    //[{ index: "", X: 4672239.183, Y: 5527455.368 }, { index: "", X: 4672271.425, Y: 5527437.256 }
    //, { index: "", X: 4672212.606, Y: 5527302.503 }, { index: "", X: 4672195.575, Y: 5527328.178 }
    //, { index: "", X: 4672189.475 , Y: 5527341.507 }];
  currentSRLabel: string = '';
  newPoint: CoordinateItem = new CoordinateItem();
  sourceSR: any[] = [{ value: 'WGS', name: 'Географічні координати' }];
    //[{ value: 'Ukraine_2000_GK_Zone_4SR', name: 'Ukraine_2000_GK_Zone_4' }// { value: 'WGS', name: 'WGS' }, 
    //, { value: 'Ukraine_2000_GK_Zone_5SR', name: 'Ukraine_2000_GK_Zone_5' }, { value: 'Ukraine_2000_GK_Zone_6SR', name: 'Ukraine_2000_GK_Zone_6' }
    //, { value: 'Ukraine_2000_GK_Zone_7SR', name: 'Ukraine_2000_GK_Zone_7' }];
  graphicsLayer: any;// __esri.GraphicsLayer;
  editFeature: __esri.Graphic;
  sketchViewModel: any;// __esri.SketchViewModel;
  geometryType: "point" | "multipoint" | "polyline" | "polygon" | "extent" = "polygon";
  layerGuid: string;
  currentPath: string;
  private routeSub: Subscription;
  sourceCoordSystems: any[] = [{ value: 'WGS', name: 'Географічні координати' }, { value: 'USK2000', name: 'УСК-2000' }];
  selectedCoordSystems: 'WGS' | 'USK2000' = 'WGS';
  showCoordSystems: boolean = false;
  changeGeometry: boolean = false;
  isNewFeature: boolean = false;
  currentServiceName: string;
  currentLayerName: any;
  currentSubTypeId; any;
  showDocuments: boolean = true;
  enableCoordSystems: boolean = true;
  enableChangeGeometry: boolean = false;
  popupAddCoord: boolean = false;
  addedCoordIndex: number = 0;
  showGeometrySettings: boolean = true;
  autoCalcCoordinates: boolean = false;
  isClosing: boolean = false;
  @ViewChild('documentUpload', { static: false }) documentUpload: DocumentUploadComponent;
  closeButtonOptions: Record<string, unknown>;
  saveButtonOptions: Record<string, unknown>;
  closePointButtonOptions: Record<string, unknown>;
  savePointButtonOptions: Record<string, unknown>;
  closeCoordinatesButtonOptions: Record<string, unknown>;
  isAzureSettingsInit: boolean = false;
  isAfterViewInit: boolean = false;

  constructor(
    private sharedService: SharedService,
    private layersService: LayersService,
    private router: Router,
    private esriService: EsriService,
    private sketchViewService: SketchViewService,
    private azureStorageService: AzureStorageService,
    private userService: UserService,
    private layerServerService: LayerServerService,
    private route: ActivatedRoute,
    private filterAttributeService: FilterAttributeService,
    private configService: ConfigService,
    private mapService: MapService,
    private legendQueryServce: LegendQuerySevice
  ) {
    super();
    this.closeButtonOptions = {
      text: 'Скасувати',
      stylingMode: 'text',
      type: 'normal',
      height: '48',
      onClick: this.goBack.bind(this),
    };
    this.saveButtonOptions = {
      text: 'Зберегти',
      stylingMode: 'text',
      type: 'default',
      height: '48',
      onClick: this.save.bind(this),
    };
    this.closePointButtonOptions = {
      text: 'Скасувати',
      stylingMode: 'text',
      type: 'normal',
      height: '48',
      onClick: this.cancelCoord.bind(this),
    };
    this.savePointButtonOptions = {
      text: 'Зберегти',
      stylingMode: 'text',
      type: 'default',
      height: '48',
      onClick: this.addNewPoint.bind(this),
    };
    this.closeCoordinatesButtonOptions = {
      text: 'Закрити',
      stylingMode: 'text',
      type: 'normal',
      height: '48',
      onClick: this.closeCoordinates.bind(this),
    };
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      console.log("edit geodata after init");
      if (this.documentUpload) {
        this.documentUpload.containerName = this.documentContainerName;
        this.isAfterViewInit = true;
        console.log("edit geodata after init. documentUpload.containerName", this.documentUpload.containerName);
      }
    }, 0);
      
  }

  ngOnDestroy(): void {
    this.mapService.isEditing = false;
    this.isClosing = true;
    this.mapService.clearViewContainerRef();
    let _map = this.sharedService.map as __esri.Map;
    _map.layers.remove(this.graphicsLayer);
    this.sketchViewModel?.destroy();
    this.routeSub?.unsubscribe();
  }

  async ngOnInit() {
    this.popupVisible = true;
    let _self = this;
    this.mapService.isEditing = true;
    this.routeSub = this.route.params.subscribe( async params => {
      _self.layerGuid = params['layerguid'];
      if (_self.layerGuid) {
        _self.isNewFeature = true;
        _self.enableCoordSystems = true;
        this.showDocuments = false;
      } else {
        _self.selectedFeature = _self.sharedService.SelectedFeatureValue();
        let tempLayer = (_self.selectedFeature.sourceLayer as __esri.Sublayer) ?? (_self.selectedFeature.layer as __esri.FeatureLayer)
        _self.layerGuid = tempLayer.get<string>('LayerDataGUID');
      }

      _self.currentSubTypeId = parseInt(params["subtypeid"]);

      this.maxAmountFiles = this.sharedService.RegionAmountFiles;
      this.selectedFeature = this.sharedService.SelectedFeatureValue();
      

      if (this.selectedFeature?.layer && this.selectedFeature?.layer.id == 'customLayerID') {        
        _self.enableChangeGeometry = true;
        _self.customFeatureInit();
      } else {
        let layer = await _self.initLayerInfo(_self.layerGuid);
        if (_self.isNewFeature) {
          if (_self.currentSubTypeId) {
            let subtypeItem = _self.attributes.find(x => x.filterType == this.filterAttributeService.TypeSubType);
            if (subtypeItem) {
              subtypeItem.value = _self.currentSubTypeId;
            }
          }
          _self.changeGeometry = true;
          _self.initDraw();
        } else {
          _self.enableChangeGeometry = true;
          _self.selectedFeautureInit(layer);
        }
      }
    });         
  }

  private async initLayerInfo(layerGuid) {
    let layer: LayerDataWithAttr = await firstValueFrom(this.layersService.getLayerData(layerGuid));
    this.currentServiceName = layer.serviceName;
    this.layerName = layer.name;
    this.currentLayerName = layer.layerName;    
    this.isNavigationLayer = (this.selectedFeature?.layer && this.selectedFeature.layer.id == 'navigationLayerID');
    let layerUrl = this.configService._baseUrlRegionServices + `${layer.serviceName}` + `/` + `${layer.layerName}`;
    let layerInfo = await this.layerServerService.getLayerInfo(layerUrl);
    this.geometryType = this.layerServerService.serverTypeToEsriType(layerInfo?.geometryType);
    this.attributes = this.getLayerDataItems(layer, layerInfo, this.isNewFeature);
    return layer;
  }

  private selectedFeautureInit(layerData: LayerDataWithAttr) {
    this.sharedService.setHiddenSearching(true);
    if (this.selectedFeature) {
      this.popupVisible = true;
    } else {
      this.closePopup();
      return;
    }
    this.azureSettingsInit(layerData.id, this.selectedFeature.attributes["OBJECTID"]);
    ////////////////////////////////
    for (const dataItem of this.attributes) {
      dataItem.value = this.selectedFeature.attributes[dataItem.name];
    }
  }

  private customFeatureInit() {
    this.sharedService.setHiddenSearching(true);
    if (this.selectedFeature) {
      this.popupVisible = true;
    } else {
      this.closePopup();
      return;
    }
    this.isCustomLayer = true;
    this.featureAttr = this.selectedFeature.attributes;
    this.geometryType = this.selectedFeature.geometry?.type;
    if (!this.userService.User) {
      this.showDocuments = false;
    }
    this.azureSettingsInit(this.sharedService.getRegionIDValue(), this.selectedFeature.attributes["OBJECTID"]);    

    this.attributes = new Array<any>();
    this.attributes.push({
      'name': 'Name', 'value': this.featureAttr['Name'], 'visible': true, 'alias': 'Назва',
      fieldType: 'string',
      filterType: '',
      editable: true,
      source: []
    });
    this.attributes.push({
      'name': 'Description', 'value': this.featureAttr['Description'], 'visible': true, 'alias': 'Опис',
      fieldType: 'string',
      filterType: '',
      editable: true,
      source: []
    });

    let color = (this.selectedFeature as __esri.Graphic).symbol.get<any>("color");
    this.currentType = (this.selectedFeature as __esri.Graphic).symbol.type;
    switch (this.currentType) {
      case "simple-marker":
        this.styleDataSource = this.getPointStyles();
        this.currentWidth = (this.selectedFeature as __esri.Graphic).symbol.get<any>("size");
        break;
      case "simple-line":
        this.styleDataSource = this.getLineStyles();
        this.currentWidth = (this.selectedFeature as __esri.Graphic).symbol.get<any>("width");
        break;
    }

    this.currentStyle = (this.selectedFeature as __esri.Graphic).symbol.get<any>("style");

    switch (this.currentStyle) {
      case "cross":
      case "x":
        let symb = (this.selectedFeature as __esri.Graphic).symbol;
        this.CurrentColor = symb.get<any>("outline.color");
        break;
      case "circle":
      case "square":
      case "diamond":
      default:
        this.CurrentColor = color;
        break;
    }
  }

  private azureSettingsInit(layerDataID, objectid) {    
    this.documentContainerName = `${layerDataID}-${objectid}`;
    console.log("azure settings init - ", this.documentContainerName);
    if (this.isAfterViewInit) {
      if (this.documentUpload) {
        this.documentUpload.containerName = this.documentContainerName;
        this.isAfterViewInit = true;
        console.log("azure Settings Init. documentUpload.containerName", this.documentUpload.containerName);
      }
    }
    this.azureStorageService.listBlobsByContainer(this.documentContainerName).then(result => {
      this.listDocuments = result;
      this.isMaxFiles = this.listDocuments.length >= this.maxAmountFiles;
      if (this.documentUpload) {
        this.documentUpload.isDisabled = this.isMaxFiles;
      }      
    })
  }

  goBack() {
    //this.location.back();
    this.closePopup();
  }

  closePopup() {
    // Providing a `null` value to the named outlet
    // clears the contents of the named outlet    
    this.router.navigate(['./', { outlets: { 'modalrouter': null } }], { skipLocationChange: true });
  }

  save() {
    if (this.isCustomLayer) {
      this.saveCustomFeature();
    } else {
      this.saveFeature();
    }    
  }

  saveFeature() {
    let updGraphic: __esri.Graphic = new this.esriService.Graphic();
    if (this.isNewFeature) {
      let attr = this.attributes.reduce((acc, item) => {
        acc[item.name] = item.value;
        return acc;
      }, {});
      updGraphic.attributes = attr;
    } else {
      this.attributes.forEach(x => {
        this.selectedFeature.attributes[x.name] = x.value;
      })
      updGraphic.attributes = this.selectedFeature.attributes;
    }
    if (this.changeGeometry) {
      if (!this.editFeature || !this.editFeature.geometry) {
        notify("Неможливо зберегти. Створіть обєкт на мапі", "info", 4000);
        return;
      }
      updGraphic.geometry = this.editFeature.geometry;
    }
    let layerUrl = this.configService._baseUrlRegionServices + `${this.currentServiceName}` + `/` + `${this.currentLayerName}`;
    let _url = layerUrl.replace('MapServer', 'FeatureServer');
    let tempLayer: __esri.FeatureLayer = new this.esriService.FeatureLayer({
      url: _url,
    });
        
    let newFeatures = this.isNewFeature ? [updGraphic] : [];
    let updFeatures = !this.isNewFeature ? [updGraphic] : [];
    tempLayer.applyEdits({
      addFeatures: newFeatures,
      updateFeatures: updFeatures
    }).then(async val => {
      let refreshLayerUrl = `${this.configService._baseUrlRegionServices}${this.currentServiceName}`;      
      this.mapService.refreshLayers(refreshLayerUrl, this.currentLayerName);
      if (this.isNewFeature) {
        const layerInfo = await this.layerServerService.getLayerInfo(layerUrl);
        const subTypeInfo = this.layerServerService.subTypeInfo(layerInfo);
        let legend: LegendItem;
        let layerDataGuid = this.layerGuid;

        if (subTypeInfo?.subtypeFieldName) {
          let subTypeID = updGraphic.attributes[subTypeInfo.subtypeFieldName];
          legend = this.sharedService.publicLegends.find(x => x.layerGuid == layerDataGuid &&
            x.id == this.currentLayerName
            && x.defaultValues.find(f => f == subTypeID));
        } else {
          legend = this.sharedService.publicLegends.find(x => x.layerGuid == layerDataGuid &&
            x.id == this.currentLayerName );
        }
        if (legend) {
          let subQuery = this.legendQueryServce.getSubQuery(legend);
          this.mapService.getRecordCountByUrl(layerUrl, subQuery).then(result => {
            if (legend) {
              legend.totalCount = result;
            }
          })
        }        
      }
      if (this.isNavigationLayer) {
        let tempGraphic: __esri.Graphic = new this.esriService.Graphic();
        Object.assign(tempGraphic, this.selectedFeature);
        tempGraphic.layer = this.selectedFeature.layer;
        tempGraphic.attributes = this.selectedFeature.attributes;
        if (this.changeGeometry) {
          if (this.editFeature && this.editFeature.geometry) {
            tempGraphic.geometry = this.editFeature.geometry;
          }          
        }
        tempGraphic.set<any>("sourceLayer", this.selectedFeature.sourceLayer);
        this.sharedService.setSelectedFeature(tempGraphic);
        let _map = this.sharedService.map as __esri.Map;
        let _navigateLayer = _map.layers.find(layer => layer.id == "navigationLayerID");
        if (_navigateLayer) {
          let tmp = (_navigateLayer as __esri.GraphicsLayer).graphics.find(x => x.attributes["OBJECTID"] == tempGraphic.attributes["OBJECTID"]);

          tmp.attributes = tempGraphic.attributes;
          tmp.popupTemplate.content = this.GetPopupContentFeaure(tmp);
          if (this.changeGeometry) {
            (_navigateLayer as __esri.GraphicsLayer).graphics.remove(tmp);
            (_navigateLayer as __esri.GraphicsLayer).graphics.add(tmp);
            if (this.selectedFeature) {
              this.selectedFeature.geometry = this.editFeature.geometry;
            }
          }
        }

        this.sharedService.setRefreshNavigationItem(tempGraphic);        
      }
      this.closePopup();
    })
  }

  saveCustomFeature() {
    if (this.isCustomLayer) {
      let _graphicLayer = this.sharedService.mapView.layerViews.find(x => x.layer.id == 'customLayerID').layer;
      let updGraphic = (_graphicLayer as __esri.GraphicsLayer).graphics.find(x => x.attributes['OBJECTID'] == this.selectedFeature.attributes['OBJECTID']);
      if (updGraphic) {
        let attr = this.attributes.reduce((acc, item) => {
          acc[item.name] = item.value;
          return acc;
        }, {});
        updGraphic.attributes['Name'] = attr['Name'];
        updGraphic.attributes['Description'] = attr['Description'];

        switch ((this.selectedFeature as __esri.Graphic).symbol.type) {
          case "simple-marker":
            switch (this.currentStyle) {
              case "cross":
                updGraphic.set<any>("symbol", this.sketchViewService.createPointSymbol(this.currentStyle, this.CurrentColor, this.currentWidth, this.CurrentColor));
                break;
              case "x":
                updGraphic.set<any>("symbol", this.sketchViewService.createPointSymbol(this.currentStyle, this.CurrentColor, this.currentWidth, this.CurrentColor));
                break;
              case "circle":
              case "square":
              case "diamond":
              case "triangle":
              default:
                updGraphic.set<any>("symbol", this.sketchViewService.createPointSymbol(this.currentStyle, this.CurrentColor, this.currentWidth));
                break;
            }
            break;
          case "simple-line":
            updGraphic.set<any>("symbol", this.sketchViewService.createLineSymbol(this.currentStyle, this.CurrentColor, this.currentWidth));
            break;
          case "simple-fill":
            updGraphic.set<any>("symbol", this.sketchViewService.createPolygonSymbol(this.CurrentColor));
            break;
        }
        // TODO refresh graphic
        //let index = (_graphicLayer as __esri.GraphicsLayer).graphics.findIndex(x => x.attributes['OBJECTID'] == this.selectedFeature.attributes['OBJECTID']);
        //(_graphicLayer as __esri.GraphicsLayer).graphics.splice(index, 1);
        //(_graphicLayer as __esri.GraphicsLayer).graphics.push(updGraphic);

        this.sketchViewService.collectGraphics();
      }
    }
    this.closePopup();
  }

  getPointStyles(): any[] {
    let result: any[] = [{ id: 'circle', name: 'круг' }, { id: 'diamond', name: 'ромб' },  { id: 'triangle', name: 'трикутник' },
      { id: 'square', name: 'квадрат' }, { id: 'x', name: 'X' }, { id: 'cross', name: '+' }];

    return result;
  }

  getLineStyles(): any[] {
    let result: any[] = [{ id: 'dash', name: 'тире' }, { id: 'dash-dot', name: 'тире-крапка' }, { id: 'dot', name: 'крапка' },
      { id: 'long-dash', name: 'довге-тире' }, { id: 'long-dash-dot', name: 'довге-тире-крапка' }, { id: 'long-dash-dot-dot', name: 'довге-тире-крапка-крапка' },
      { id: 'short-dash', name: 'коротке-тире' }, { id: 'short-dash-dot', name: 'коротке-тире-крапка' },
      { id: 'short-dash-dot-dot', name: 'коротке-тире-крапка-крапка' },
      { id: 'short-dot', name: 'коротка-крапка' }, { id: 'solid', name: 'суцільна' }];

    return result;
  }

  downloadDoc(doc) {
    let ext = doc.substring(doc.lastIndexOf('.') + 1);
    this.sharedService.currentDocumentContainer = this.documentContainerName;
    this.sharedService.currentDocumentName = doc;
    switch (ext.toLowerCase()) {
      case "pdf":
        this.router.navigate(['./', { outlets: { viewrouter: null } }], { skipLocationChange: true }).then(val => {
          this.router.navigate(['./', { outlets: { viewrouter: ['viewdoc'] } }], { skipLocationChange: true });
        })
        break;
      case "tif":
      case "tiff":
      case "png":
      case "jpg":
      case "jpeg":
        this.router.navigate(['./', { outlets: { viewrouter: null } }], { skipLocationChange: true }).then(val => {
          this.router.navigate(['./', { outlets: { viewrouter: ['viewimage'] } }], { skipLocationChange: true });
        })
        break;
      default:
        this.azureStorageService.getBlob(this.documentContainerName, doc).then(() => {
          this.azureStorageService.listBlobsByContainer(this.documentContainerName).then(result => {
            this.listDocuments = result;
          })
        });
        break;
    }
  }

  deleteDoc(doc) {
    this.azureStorageService.deleteBlob(this.documentContainerName, doc).then(() => {
      this.azureStorageService.listBlobsByContainer(this.documentContainerName).then(result => {
        this.listDocuments = result;
        this.isMaxFiles = this.listDocuments.length >= this.maxAmountFiles;
        this.documentUpload.isDisabled = this.isMaxFiles;
      })
    });
  }

  refreshFiles(obj) {
    this.azureStorageService.listBlobsByContainer(this.documentContainerName).then(result => {
      this.listDocuments = result;
      this.isMaxFiles = this.listDocuments.length >= this.maxAmountFiles;
      this.documentUpload.isDisabled = this.isMaxFiles;
    })
  }


  private GetPopupContentFeaure(feature) {
    let result = '*';
    let _feauture = feature;
    let sourceLayer = (_feauture.layer as __esri.FeatureLayer);
    let attributes: LayerDataAttribute[] = sourceLayer.get<any>("layerAttributes");  
    let fields = sourceLayer.fields;
    
    result = '<ul class="esri-popup__list">';

    result += `<span style="display:none">OBJECTID : {OBJECTID}</span>`;
    attributes.forEach(x => {
      if (x.inMini) {
        let field = fields.find(f => f.name == x.name)
        let _value = '';
        if (sourceLayer.typeIdField && sourceLayer.typeIdField == x.name) {
          let _id = _feauture.attributes[x.name] ? _feauture.attributes[x.name] : "";
          let typeValue = sourceLayer.types.find(t => t.id == _id);
          if (typeValue) {
            _value = typeValue.name;
          }
        } else {
          _value = _feauture.attributes[x.name] ? _feauture.attributes[x.name] : "";
          let url;
          let isUrl: boolean = false;
          try {
            url = new URL(_value);
            isUrl = url.protocol === "http:" || url.protocol === "https:";
          } catch (_) {
            isUrl = false;
          }
          if (isUrl) {
            _value = `<a target='blank' href='${_value}'>Більше...</a>`
          }
        }

        result += `<li><span class=esri-popup__text-secondary>${field.alias}</span> : <span class="esri-popup__text-primary">${_value}</span></li>`;
      }

    })
    result += '</ul>';
    return result;
  }

  apply() {
    this.draw();
  }

  addNewPoint() {
    //this.sourceCoordinates = [{ index: "", X: 4672239.183, Y: 5527455.368 }, { index: "", X: 4672271.425, Y: 5527437.256 }
    //  , { index: "", X: 4672212.606, Y: 5527302.503 }, { index: "", X: 4672195.575, Y: 5527328.178 }
    //  , { index: "", X: 4672189.475, Y: 5527341.507 }];
    //return;
    const validationResult = validationEngine.validateGroup('pointgroup');
    if (!validationResult.isValid) {
      return;
    }

    let item: CoordinateItem = {
      index: this.addedCoordIndex, //?? this.sourceCoordinates.length,
      X: this.newPoint.X,
      Y: this.newPoint.Y
    }
    this.sourceCoordinates.splice(this.addedCoordIndex, 0, item);
    let i = 0;
    for (const point of this.sourceCoordinates) {
      point.index = i++;
    }

    this.newPoint = new CoordinateItem();
    if (this.autoCalcCoordinates && this.enableApplyBtn()) {
      this.draw();
    }

    validationEngine.resetGroup('pointgroup');

    this.popupAddCoord = false;
  }

  draw = async () => {
    this.initDraw();
    let geometry = await this.getGeometry();
    this.startDraw(geometry);
  }

  startDraw(geometry) {
    if (!this.editFeature) {
      let _symbol = new this.esriService.SimpleFillSymbol();
      switch (this.geometryType) {
        case "point":
          _symbol = MapConfig.drawPointSymbol;
          break;
        case "multipoint":
          _symbol = MapConfig.popupMultiPointSymbol;
          break;
        case "polyline":
          _symbol = MapConfig.drawPolylineSymbol;
          break;
        case "polygon":
        //case "extent":
        default:
          _symbol = MapConfig.drawPolygonSymbol;
          break;
      }
      let graphic: __esri.Graphic = new this.esriService.Graphic({
        geometry: geometry,
        symbol: _symbol,

      });
      //if ((this.sketchViewModel as __esri.SketchViewModel).createGraphic) {
      //  (this.sketchViewModel as __esri.SketchViewModel).delete();
      //}
      this.graphicsLayer.add(graphic);
      this.editFeature = graphic;
    } else {
      this.editFeature.geometry = geometry;
    }

    this.sketchViewModel.update([this.editFeature]).then(val => {
      this.sketchViewModel.complete();
    })
    
    let opts = {
      duration: 500
    };
    //pointInWGS84.geometry = this.esriService.webMercatorUtils.webMercatorToGeographic(pointInWGS84.geometry);
    let _feature = this.esriService.webMercatorUtils.webMercatorToGeographic(geometry);
    let _zoom = this.sharedService.mapView.zoom < 18 ? 18 : this.sharedService.mapView.zoom;
    this.sharedService.mapView.goTo({ target: _feature, zoom: _zoom }, opts); 
  }

  private initDraw() {
    if (this.graphicsLayer) {
      return;
    }
      if (!this.graphicsLayer) {
      this.graphicsLayer = new this.esriService.GraphicsLayer({
        id: "tempLayerID"
      })
    }

    this.sharedService.map.layers.add(this.graphicsLayer);

    this.sketchViewModel = new this.esriService.SketchViewModel({
      view: this.sharedService.mapView,
      updateOnGraphicClick: true,
      layer: this.graphicsLayer,
      pointSymbol: MapConfig.drawPointSymbol,
      polylineSymbol: MapConfig.drawPolylineSymbol,
      polygonSymbol: MapConfig.drawPolygonSymbol,
      snappingOptions: { // autocasts to SnappingOptions()
        enabled: true, // global snapping is turned on
        // assigns a collection of FeatureSnappingLayerSource() and enables feature snapping on this layer
        featureSources: [{ layer: this.graphicsLayer, enabled: true }]
      }
    });
    let coordWidget = this.mapService.InitCoordWidget();
    switch (this.geometryType) {
      case "point":
        this.sketchViewModel.create("point");
        break;
      case "polyline":
        this.sketchViewModel.create("polyline");
        coordWidget.isPolyline = true;
        break;
      case "polygon":
        this.sketchViewModel.create("polygon");
        coordWidget.isPolygon = true;
        break;
    }
    
    let _self = this;
    this.sketchViewModel.on("create", async function (event) {
      if (event.state == "complete") {
        _self.editFeature = event.graphic;
        _self.mapService.clearViewContainerRef();
        
        if (_self.autoCalcCoordinates && _self.enableConvert(_self.editFeature?.geometry)) {          
          _self.sourceCoordinates = await _self.returnCoordinates(_self.editFeature.geometry);
          //console.log("sketchViewModel create complete - ", event);
        }
      }
      if (event.state == "cancel" && !_self.isClosing) {
        switch (_self.geometryType) {
          case "point":
            _self.sketchViewModel.create("point");
            break;
          case "polyline":
            _self.sketchViewModel.create("polyline");
            break;
          case "polygon":
            _self.sketchViewModel.create("polygon");
            break;
        }
        if (_self.editFeature) {
          _self.editFeature = undefined;
        }
      }
    })
    this.sketchViewModel.on("update", async function (event) {
      if (event.state == "complete") {
        _self.editFeature = event.graphics?.length > 0 ? event.graphics[0] : undefined;        
        _self.mapService.clearViewContainerRef();
        if (_self.autoCalcCoordinates && _self.enableConvert(_self.editFeature?.geometry)) {
          _self.sourceCoordinates = await _self.returnCoordinates(_self.editFeature.geometry);
          
        }
      } else if (event.state != "complete") {
        if (coordWidget.isPolyline) {
          var distance = _self.esriService.GeometryEngine.geodesicLength(event.graphics[0].geometry, "meters");
          if (distance < 0) {
            // simplify the polygon if needed and calculate the area again
            var simplifiedPolygon = _self.esriService.GeometryEngine.simplify(event.graphics[0].geometry);
            if (simplifiedPolygon) {
              distance = _self.esriService.GeometryEngine.geodesicLength(simplifiedPolygon, "meters");
            }
          }

          coordWidget.lengthLine = distance.toFixed(3);
        }

        if (coordWidget.isPolygon) {
          let path: any[] = (event.graphics[0].geometry as __esri.Polygon).rings[0];
          let allPath = Object.assign([], path);
          //allPath.splice((path.length - 1), 1);
          let _polyline = new _self.esriService.Polyline({
            spatialReference: _self.sharedService.mapView.spatialReference,
            hasZ: false,
            paths: allPath
          });

          var distance = _self.esriService.GeometryEngine.geodesicLength(_polyline, "meters");
          if (distance < 0) {
            // simplify the polygon if needed and calculate the area again
            var simplifiedPolygon = _self.esriService.GeometryEngine.simplify(_polyline);
            if (simplifiedPolygon) {
              distance = _self.esriService.GeometryEngine.geodesicLength(simplifiedPolygon, "meters");
            }
          }
          coordWidget.lengthLine = distance.toFixed(3);

          var area = _self.esriService.GeometryEngine.geodesicArea(event.graphics[0].geometry, SquareUnits.meter);
          var areaHa = _self.esriService.GeometryEngine.geodesicArea(event.graphics[0].geometry, SquareUnits.hectares);
          if (area < 0 || areaHa < 0) {
            // simplify the polygon if needed and calculate the area again
            let simplifiedPolygon = _self.esriService.GeometryEngine.simplify(event.graphics[0].geometry);
            if (simplifiedPolygon) {
              area = _self.esriService.GeometryEngine.geodesicArea(simplifiedPolygon, SquareUnits.meter);
              areaHa = _self.esriService.GeometryEngine.geodesicArea(simplifiedPolygon, SquareUnits.hectares);
            }
          }

          coordWidget.areaPolygon = area.toFixed(3);
        }
        //return;
      }
    })
    
  }

  async getGeometry(): Promise<__esri.Polygon | __esri.Polyline | __esri.Point> {
    //let geometrytype: "point" | "multipoint" | "polyline" | "polygon" = "polygon";
    let isWGS: boolean = this.currentSR == "WGS";
    let spatialReference = isWGS ? MapConfig.spatialReferenceWGS : this.getUSK() // MapConfig.Ukraine_2000_GK_Zone_4SR;    // MapConfig[this.currentSR.value]// 
    let rings = this.sourceCoordinates.map(res => {
      const [x, y] = this.selectedCoordSystems == "WGS" ?
        this.esriService.webMercatorUtils.lngLatToXY(res.X, res.Y)
        : [res.X, res.Y];

      return [x, y];
    })
    let allPath = Object.assign([], rings);
    //allPath.splice((rings.length - 1), 1);
    let geometry: __esri.Polygon | __esri.Polyline | __esri.Point;// __esri.Geometry;
    switch (this.geometryType) {
      case "point":

        geometry = new this.esriService.Point({
          y: rings[0][1],
          x: rings[0][0],
          spatialReference: spatialReference
        });
        break;
      case "multipoint":
        
        break;
      case "polyline":
        geometry = new this.esriService.Polyline({
          paths: allPath,
          spatialReference: spatialReference,
          hasZ: false,
        })
        break;
      case "polygon":
      //case "extent":
      default:
        geometry = new this.esriService.Polygon({
          rings: allPath,
          spatialReference: spatialReference,
          hasZ: false,
        })
        break;
    }
    if (isWGS) {
      return geometry;
    }
    return await this.project(geometry);
  }

  async project(feature) {
    let wgs84SR = MapConfig.spatialReferenceWGS;
    let usk2000SR4 = this.getUSK();  
    await this.esriService.Projection.load();
    let transformation = this.esriService.Projection.getTransformation(usk2000SR4, wgs84SR);
    let featureInWGS84 = this.esriService.Projection.project(feature, wgs84SR, transformation);
    
    return featureInWGS84;
  }

  getSpatialReference() {

  }

  getUSK() {
    let sp_value = this.currentSR;
    let val = MapConfig[sp_value];
    return val;
  }

  getLayerDataItems(layer: LayerDataWithAttr, layerInfo, isNew: boolean = true){
    let currentAttributes: LayerDataItem[] = [];
    let subTypeInfo = this.layerServerService.subTypeInfo(layerInfo);
    let layerFields: any[] = layerInfo?.fields ?? []; 

      if (subTypeInfo.subtypeFieldName && subTypeInfo.subtypes?.length > 0) {
        let subtypes = subTypeInfo.subtypes.map(s => {
          return { value: Number(s.id), name: s.name }
        })
        let field = layerFields ? layerFields.find(f => f.name == subTypeInfo.subtypeFieldName) : null;
        let alias = `${(field ? field.alias : subTypeInfo.subtypeFieldName)}`;
        let item: LayerDataItem =
        {
          name: subTypeInfo.subtypeFieldName,
          alias: alias,
          filterType: this.filterAttributeService.TypeSubType,
          fieldType: this.layerServerService.serverFieldTypeToEsri(field.type),
          visible: isNew,
          source: subtypes,
          editable: isNew, 
          value: subtypes[0].value,
        }
        currentAttributes.push(item);
      }   
    for (const attr of layer.layerAttributes.filter(f => f.hasFilter || f.editable)) {

      let attrSource: { value: any; name: any }[] = [];
      if (attr.hasFilter) {
        switch (attr.filterType) {
          case this.filterAttributeService.TypeKOATUU:
            let sourceItem: AttributeSourceItem = { value: this.sharedService.CurrentRegionCode, name: this.sharedService.CurrentRegionCode };
            attrSource.push(sourceItem);
            break;
          case this.filterAttributeService.TypeSubType:
            let subtypeItems: AttributeSourceItem[] = attr.subTypeFilters.map(st => {
              return { value: st.subTypeID, name: st.subTypeValue }
            });
            let layerSubTypeItem = currentAttributes.find(f => f.filterType == this.filterAttributeService.TypeSubType);
            layerSubTypeItem.source = subtypeItems;
            layerSubTypeItem.value = subtypeItems?.length > 0 ?
                                      subtypeItems[0].value: undefined;
            break;
          case this.filterAttributeService.TypeGroup:
            let groupItems: AttributeSourceItem[] = attr.groupValueFilters.map(g => {
              return { value: g.value, name: g.value }
            })
            attrSource.push(...groupItems);
            break;
        }
      }
      if (attr.name != subTypeInfo.subtypeFieldName) {
        let field = layerFields ? layerFields.find(f => f.name == attr.name) : null;
        let fieldName = `${(field ? field.alias : attr.name)}`;
        if (field.domain) {
          let values: any[] = field.domain.codedValues;
          attrSource = values.map(val => {
            return { value: val.code, name: val.name };
          });
        }
        let item: LayerDataItem = {
          name: attr.name,
          alias: fieldName,
          fieldType: this.layerServerService.serverFieldTypeToEsri(field.type),
          filterType: attr.hasFilter ? attr.filterType : undefined,
          visible: isNew ? 
            (attr.filterType == this.filterAttributeService.TypeKOATUU? attr.editable : true) : attr.editable, 
          editable: true, //isNew || attr.editable,
          source: attrSource,
          value: attrSource?.length > 0 ? attrSource[0].value : undefined,
        }
        currentAttributes.push(item);
      } else {
        let layerSubTypeItem = currentAttributes.find(f => f.filterType == this.filterAttributeService.TypeSubType);
        if (layerSubTypeItem) {
          layerSubTypeItem.visible = isNew ? true : attr.editable;
          layerSubTypeItem.editable = isNew ? true : attr.editable;
        }
      }

    }
    return currentAttributes;
  }

  onCoordSystemsValueChanged(e) {
    this.initSourceCoordSystem(e.value);
  }

  onCoordSystemsListChanged = async (e) => {
    if (this.autoCalcCoordinates && this.enableConvert(this.editFeature?.geometry)) {
      this.sourceCoordinates = await this.returnCoordinates(this.editFeature.geometry);
    }
    //else {
    //  notify("Не вдалося перетворити координати", "info", 3000);
    //  this.sourceCoordinates = [];
    //}
  }

  initSourceCoordSystem(value) {
    switch (value) {
      case "WGS":
        this.sourceSR = [{ value: 'WGS', name: 'Географічні координати' }];
        this.currentSR = 'WGS';
        break;
      case "USK2000":
        this.sourceSR = [{ value: 'Ukraine_2000_GK_Zone_4SR', name: 'Ukraine_2000_GK_Zone_4' }
          , { value: 'Ukraine_2000_GK_Zone_5SR', name: 'Ukraine_2000_GK_Zone_5' }, { value: 'Ukraine_2000_GK_Zone_6SR', name: 'Ukraine_2000_GK_Zone_6' }
          , { value: 'Ukraine_2000_GK_Zone_7SR', name: 'Ukraine_2000_GK_Zone_7' }]
        this.currentSR = 'Ukraine_2000_GK_Zone_4SR';
        break;
    }
  }

  deletePoint(point) {
    let index = (point as CoordinateItem).index;
    if (index < this.sourceCoordinates.length) {
      this.sourceCoordinates.splice(index, 1);
      let i = 0;
      for (let item of this.sourceCoordinates) {
        item.index = i++;
      }
    }
    if (this.autoCalcCoordinates) {
      if (this.enableApplyBtn()) {
        this.draw();
      } else {
        if ((this.sketchViewModel as __esri.SketchViewModel).createGraphic) {
          (this.sketchViewModel as __esri.SketchViewModel).delete();
        }
        this.graphicsLayer.remove(this.editFeature);        
        this.editFeature = undefined;

        switch (this.geometryType) {
          case "point":
            this.sketchViewModel.create("point");
            break;
          case "polyline":
            this.sketchViewModel.create("polyline");
            break;
          case "polygon":
            this.sketchViewModel.create("polygon");
            break;
        }
      }
    }
  }

  loadCoordinates() {

  }

  onGeometryValueChanged(e) {
    if (e.value) {
      this.isClosing = false;
      this.initDraw();
      if (!this.isNewFeature) {
        let geometry = this.selectedFeature.geometry;
        this.startDraw(geometry);
      }
    } else {
      this.isClosing = true;
      this.sketchViewModel.cancel();
      let _map = this.sharedService.map as __esri.Map;
      this.graphicsLayer.remove(this.editFeature);      
      _map.layers.remove(this.graphicsLayer);
      this.editFeature = undefined;
      this.graphicsLayer = undefined;
      this.sketchViewModel.destroy();
      //this.sketchViewModel = undefined;
    }    
  }

  onCoordinateSystemsValueChanged = async (e) => { 
    if (e.value && this.autoCalcCoordinates && this.enableConvert(this.editFeature?.geometry)) {
      this.sourceCoordinates = await this.returnCoordinates(this.editFeature.geometry);
    }
    //else {
    //  this.sourceCoordinates = [];
    //}
  }

  onCoordinateSystemsButtonClick() {
    this.showCoordSystems = !this.showCoordSystems;
    this.onCoordinateSystemsValueChanged({ value: this.showCoordSystems });
  }

  async convert(feature) {
    let wgs84SR = MapConfig.spatialReferenceWGS;
    let usk2000SR4 = this.getUSK();
    await this.esriService.Projection.load();
    let transformation = this.esriService.Projection.getTransformation(wgs84SR,usk2000SR4);
    let featureInUSK = this.esriService.Projection.project(feature, usk2000SR4, transformation);

    return featureInUSK;
  }

  private async returnCoordinates(_geometry) {
    let sourceCoordinates: CoordinateItem[] = [];
    
    let geometry;
    {
      if (this.selectedCoordSystems == "USK2000") {
        geometry = await this.convert(_geometry);
      } else if (this.selectedCoordSystems == "WGS") {
        geometry = _geometry
      }
      let _index = 0;
      switch (geometry.type) {
        case "polygon":          
          const rings = geometry.rings;
          rings.forEach((ring) => {
            ring.forEach((point) => {
              const [x, y] = this.selectedCoordSystems == "WGS" ?
                this.esriService.webMercatorUtils.xyToLngLat(point[0], point[1])
                : [point[0], point[1]];
              //let item = { index: _index++, X: point[0], Y: point[1] };
              let item = { index: _index++, X: x, Y: y };
              sourceCoordinates.push(item)
            });
          });
          break;
        case "polyline":
          const path = geometry.paths;
          path.forEach((ring) => {
            ring.forEach((point) => {
              const [x, y] = this.selectedCoordSystems == "WGS" ?
                this.esriService.webMercatorUtils.xyToLngLat(point[0], point[1])
                : [point[0], point[1]];
              //let item = { index: _index++, X: point[0], Y: point[1] };
              let item = { index: _index++, X: x, Y: y };
              sourceCoordinates.push(item);
            });
          });
          break;
        case "point":
          const [x, y] = this.selectedCoordSystems == "WGS" ?
            this.esriService.webMercatorUtils.xyToLngLat(geometry.x, geometry.y)
            : [geometry.x, geometry.y];
          const item = { index: _index++, X: x, Y: y };
          sourceCoordinates.push(item);
          break;
      }
    }
    return sourceCoordinates;
  }

  convertCoord = async ()=> {
    if (this.editFeature) {
      this.sourceCoordinates = await this.returnCoordinates(this.editFeature.geometry);
    }    
  }

  showAddCoord(index) {
    this.popupAddCoord = true;
    this.addedCoordIndex = index;
  }

  cancelCoord() {
    validationEngine.resetGroup('pointgroup');
    this.popupAddCoord = false;
  }

  enableAddButton() {
    let result: boolean = this.showCoordSystems;
    switch (this.geometryType) {
      case "point":
        result = this.sourceCoordinates.length == 0 && result;
        break;
      default:
        //result = true;
        break;
    }

    return result;
  }

  enableApplyBtn() {
    let result: boolean = false;
    switch (this.geometryType) {
      case "point":
        result = this.sourceCoordinates?.length > 0;
        break;
      case "polyline":
        result = this.sourceCoordinates?.length > 1;
        break;
      case "polygon":
        result = this.sourceCoordinates?.length > 2;
        break;
    }
    return result;
  }

  enableConvertBtn() {
    return this.enableConvert(this.editFeature?.geometry);
  }

  enableConvert(geometry) {
    let result: boolean = false;
    if (!geometry) {
      return false;
    }
    switch (this.geometryType) {
      case "point":
        let point = (geometry as __esri.Point);
        result = point?.x != null && point?.y != null;
        break;
      case "polyline":
        let polyline = (geometry as __esri.Polyline);
        result = polyline.paths?.every(path => path.length > 1) ?? false;
        break;
      case "polygon":
        let polygon = (geometry as __esri.Polygon);
        result = polygon.rings?.length > 0 && polygon.rings.every(ring => ring.length > 2);
        break;
    }
    return result;
  }

  closeCoordinates() {
    this.showCoordSystems = false;
  }

  onPointValueChanged(e, point) {
    if (this.autoCalcCoordinates && this.enableApplyBtn()) {
      this.draw();
    }
  }
}
