<dx-popup
  title="{{layerName}}"
  maxWidth="640px"
  width="100%"
  height="100%"
  position="left top"
  [showCloseButton]="false"
  [dragEnabled]="false"  
  [animation]="{ 
    hide: { type: 'slideOut', direction: 'left', duration: 512 }, 
    show: {type: 'slideIn', direction: 'left', duration: 512 }
  }"
  [shading] ="false"
  [closeOnOutsideClick]="false"
  (onHidden)="goBack()"
  [(visible)]="popupVisible">
  <div *dxTemplate="let data of 'content'" class="flex-col">
    
    <div *ngIf="isCustomLayer">
      <dx-color-box [(value)]="CurrentColor"
                    [editAlphaChannel]="true"
                    ApplyButtonText="Готово"
                    label="Колір"
                    cancelButtonText="Відміна">
      </dx-color-box>
    </div>

    <div *ngIf="(isCustomLayer&&(currentType=='simple-marker'||currentType=='simple-line'))">
      <dx-select-box [dataSource]="styleDataSource"
                      displayExpr="name"
                      valueExpr="id"
                      label="Стиль"
                      placeholder=""
                      [(value)]="currentStyle"
                      noDataText="Немає даних">
        <dx-validator>
          <dxi-validation-rule type="required" message="Обов'язкове"></dxi-validation-rule>
        </dx-validator>
      </dx-select-box>
    </div>

    <div *ngIf="(isCustomLayer&&(currentType=='simple-marker'||currentType=='simple-line'))">
      <dx-number-box [max]="32"
                      [min]="0.1"
                      [step]="0.5"
                      width="128"
                      label="Розмір"
                      [showSpinButtons]="true"
                      [(value)]="currentWidth">

      </dx-number-box>
    </div>

    <div class="flex-row" *ngIf="(!isCustomLayer && !isNewFeature) || changeGeometry">
      <div *ngIf="!isCustomLayer && !isNewFeature">
        <dx-check-box text="Змінити геометрію" [disabled]="!enableChangeGeometry" [(value)]="changeGeometry" (onValueChanged)="onGeometryValueChanged($event)"></dx-check-box>
      </div>
      <div *ngIf="changeGeometry" class="flex-row flex-end">
        <!-- <dx-check-box text="Задати координати" [(value)]="showCoordSystems" (onValueChanged)="onCoordinateSystemsValueChanged($event)" ></dx-check-box> -->
        <dx-button id="CoordinateSystemsBtn"
            text="Задати координати"
            stylingMode="outlined"
            type="default"
            (click)="onCoordinateSystemsButtonClick()">
        </dx-button>
      </div>
    </div>

    <div *ngFor="let attr of attributes | visibleFilter:true" class="dx-text-field">
      <dx-number-box *ngIf="!attr.filterType && !(attr.source?.length>0) && attr.fieldType=='number'"
                      [disabled]="!attr.editable"
                      [(value)]="attr.value">
      </dx-number-box>
      <dx-text-box *ngIf="!attr.filterType && !(attr.source?.length>0) && attr.fieldType=='string'"
                    [disabled]="!attr.editable"
                    [(value)]="attr.value">
      </dx-text-box>
      <dx-select-box *ngIf="attr.filterType||attr.source?.length>0" [dataSource]="attr.source"
                      displayExpr="name"
                      valueExpr="value"
                      [disabled]="!attr.editable"
                      placeholder=""
                      [(value)]="attr.value"
                      noDataText="Немає даних">
        <dx-validator>
          <dxi-validation-rule type="required" message="Обов'язкове"></dxi-validation-rule>
        </dx-validator>
      </dx-select-box>
      <label>{{ attr.alias }}</label>
    </div>

    <section class="flex-col">
      <div *ngIf="showDocuments" class="flex-col">
        <hr>
        <p class="heading-xs">Документи</p>
        <app-document-upload [isDisabled]="isMaxFiles" (uploadEvent)="refreshFiles($event)" #documentUpload></app-document-upload>
        <div *ngIf="isMaxFiles" class="text-field-label">
          Ліміт завантажених файлів {{maxAmountFiles}}
        </div>
      </div>
      <ul class="list-files" [hidden]="!showDocuments">
        <li *ngFor="let doc of listDocuments" class="list-files__item">
          <button type="button" class="list-files__item-btn-delete" (click)="deleteDoc(doc)">
            <span class="esri-icon-trash"></span>
          </button>
          <a (click)="downloadDoc(doc)" class="list-files__item-link" title="{{doc}}"> {{doc}} </a>
        </li>
      </ul>
    </section>
 
  </div>
  <dxi-toolbar-item
      widget="dxButton"
      toolbar="bottom"
      location="after"
      [options]="closeButtonOptions">
  </dxi-toolbar-item>
  <dxi-toolbar-item
      widget="dxButton"
      toolbar="bottom"
      location="after"
      [options]="saveButtonOptions">
  </dxi-toolbar-item>
</dx-popup>

<dx-popup
  title="Додати координати"
  maxWidth="640px"
  width="100%"
  height="100%"
  position="left top"
  [showCloseButton]="false"
  [dragEnabled]="false"  
  [animation]="{ 
    hide: { type: 'slideOut', direction: 'left', duration: 512 }, 
    show: {type: 'slideIn', direction: 'left', duration: 512 }
  }"
  [shading] ="false"
  [closeOnOutsideClick]="false"
  (onHidden)="cancelCoord()"
  [(visible)]="popupAddCoord">
  <div *dxTemplate="let data of 'content'" class="flex-col">
    <div class="flex-row">
      <div class="dx-text-field">
        <dx-number-box [(value)]="newPoint.Y" width="100%" height="48">
          <dx-validator  [validationGroup]="'pointgroup'">
            <dxi-validation-rule type="required" message="Y (широта) обов'язкове поле"></dxi-validation-rule>
            <!--<dxi-validation-rule type="range" [min]="-90" [max]="90" message="Значення Y повинно бути в діапазоні від -90 до 90"></dxi-validation-rule>-->
          </dx-validator>
        </dx-number-box>
        <label>Y (широта)</label>
      </div>
      <div class="dx-text-field">
        <dx-number-box [(value)]="newPoint.X" width="100%" height="48">
          <dx-validator [validationGroup]="'pointgroup'">
            <dxi-validation-rule type="required" message="X (довгота) обов'язкове поле"></dxi-validation-rule>
            <!--<dxi-validation-rule type="range" [min]="-180" [max]="180" message="Значення X повинно бути в діапазоні від -180 до 180"></dxi-validation-rule>-->
          </dx-validator>
        </dx-number-box>
        <label>X (довгота)</label>
      </div>
    </div>
  </div>
  <dxi-toolbar-item
      widget="dxButton"
      toolbar="bottom"
      location="after"
      [options]="closePointButtonOptions">
  </dxi-toolbar-item>
  <dxi-toolbar-item
      widget="dxButton"
      toolbar="bottom"
      location="after"
      [options]="savePointButtonOptions">
  </dxi-toolbar-item>
</dx-popup>

<dx-popup 
  title="Координати"
  maxWidth="640px"
  width="100%"
  height="100%"
  position="left top"
  [showCloseButton]="true"
  [dragEnabled]="false"  
  [animation]="{ 
    hide: { type: 'slideOut', direction: 'left', duration: 512 }, 
    show: {type: 'slideIn', direction: 'left', duration: 512 }
  }"
  [shading] ="false"
  [closeOnOutsideClick]="false"
  (onHidden)="closeCoordinates()"
  [(visible)]="showCoordSystems">
  <div *dxTemplate="let data of 'content'">
    <div *ngIf="showCoordSystems" class="flex-col">
      <dx-radio-group [dataSource]="sourceCoordSystems"
                      [(value)]="selectedCoordSystems"
                      valueExpr="value"
                      displayExpr="name"
                      (onValueChanged)="onCoordSystemsValueChanged($event)">
      </dx-radio-group>
      <hr>
      <div *ngIf="showCoordSystems" class="dx-text-field">
        <dx-select-box [dataSource]="sourceSR"
                        displayExpr="name"
                        valueExpr="value"
                        placeholder=""
                        [(value)]="currentSR"
                        (onValueChanged)="onCoordSystemsListChanged($event)"
                        noDataText="Немає даних">
          <dx-validator>
            <dxi-validation-rule type="required" message="Обов'язкове"></dxi-validation-rule>
          </dx-validator>
        </dx-select-box>
        <label>Система координат</label>
      </div>
      <hr>

      <div>
        <dx-button id="showBtn"
                    text="Додати координати"
                    stylingMode="outlined"
                    type="normal"
                    icon="plus"
                    (onClick)="showAddCoord(0)"
                    [disabled]="isSaving">
        </dx-button>
      </div>

      <div *ngFor="let point of sourceCoordinates" class="flex-col">
        <section class="card yellow content-padding">
          <div class="flex-row">
            <div class="dx-text-field yellow">
              <dx-number-box [(value)]="point.Y" (onValueChanged)="onPointValueChanged($event, point)">
              </dx-number-box>
              <label>Y (широта)</label>
            </div>
            <div class="dx-text-field yellow">
              <dx-number-box [(value)]="point.X"  (onValueChanged)="onPointValueChanged($event, point)">
              </dx-number-box>
              <label>X (довгота)</label>
            </div>
            <dx-button icon="close" hint="Видалити" stylingMode="text" (click)="deletePoint(point)"></dx-button>
          </div>
        </section>
        <div>
          <dx-button *ngIf="enableAddButton()" id="showBtn"
                      text="Додати координати"
                      stylingMode="outlined"
                      type="normal"
                      (onClick)="showAddCoord(point.index+1)"
                      [disabled]="isSaving">
          </dx-button>
        </div>
      </div>

      <section *ngIf="!autoCalcCoordinates" class="flex-col">
        <hr>
        <dx-button id="applyBtn"
                   text="Створити ОБЄКТ НА МАПІ по заданих координатах"
                   stylingMode="outlined"
                   type="normal"
                   (onClick)="apply()"
                   [disabled]="!enableApplyBtn()">
        </dx-button>
        <dx-button id="convertBtn"
                   text="Ініціалізувати КООРДИНАТИ з об'єкта на мапі"
                   stylingMode="outlined"
                   type="normal"
                   (onClick)="convertCoord()"
                   [disabled]="!enableConvertBtn()">
        </dx-button>
      </section>
      
    </div>
  </div>

  <dxi-toolbar-item
      widget="dxButton"
      toolbar="bottom"
      location="after"
      [options]="closeCoordinatesButtonOptions">
  </dxi-toolbar-item>
</dx-popup>
