import { Injectable } from '@angular/core';
import { SharedService } from "../shared/shared.service";
import { ConfigService } from "../shared/utils/config.service";
import { EsriService } from "../esri/js-esri.service";
import { LayerDataAttribute } from '../../models/layers/layer-data-attribute.models';
import { LegendItem } from '../../models/layers/legend.model';
import { LegendQuerySevice } from '../legends/legend-query.service';


@Injectable()
export class NavigationService {
  constructor(      
    private esriService: EsriService,
    private sharedService: SharedService,        
    private configService: ConfigService,
    private legendQuerySevice: LegendQuerySevice
  ) {
    
  }
  //// DO NOT USE depricated
  async getNavigationList(id, layerID, layerDataGuid, subtypeid?) {

    let legends = this.sharedService.getLegendsValue()
    let legend = subtypeid ? legends.find(x => x.layerGuid == layerDataGuid && x.id == id && x.defaultValues.find(v => v == subtypeid)) :
      legends.find(x => x.layerGuid == layerDataGuid && x.id == id);
    
    let map = this.sharedService.map as __esri.Map
    let _layer = map.findLayerById(layerID) ;
    if (_layer && (_layer as __esri.MapImageLayer).sublayers && (_layer as __esri.MapImageLayer).sublayers.length > 0) {
      let subLayer = (_layer as __esri.MapImageLayer).sublayers.find(l => l.id == id);
      let attr = subLayer.get<LayerDataAttribute[]>("layerAttributes");
      let subQuery = this.legendQuerySevice.getSubQuery(legend);
      if (subQuery.length > 0) {
        subQuery = ' and ' + subQuery;
      }
      
      let featureUrl: string = subLayer.url;
      let _url = featureUrl + "?token=" + this.configService.getGisToken();
     
      var query: __esri.Query = new this.esriService.Query();
      query.returnGeometry = true;
      query.where = '1=1 ' + subQuery;
      query.outFields = this.getAttributes(attr)
      query.returnGeometry = true;
      var _self = this;
      let featureLayer: __esri.FeatureLayer = new _self.esriService.FeatureLayer();

      featureLayer.url = featureUrl.replace('MapServer', 'FeatureServer');

      featureLayer.outFields = _self.getAttributes(attr);
      featureLayer.popupTemplate = new _self.esriService.PopupTemplate();
      let result = await featureLayer.queryFeatures(query).then(res => {
        return res;
      })
      featureLayer.visible = false;
      legend.totalCount = result.features.length;           
      let _set = {
        featureSet: result,
        featureLayer: featureLayer,
        subLayer: subLayer,
        legend: legend
      }
      return _set;
     
    }
  }

  async getNavigationListByLegend(legend: LegendItem) {
    let layerID = legend.layerID;
    let map = this.sharedService.map as __esri.Map
    let _layer = map.findLayerById(layerID);
    let subLayer: __esri.Sublayer;
    let attr;
    if (legend.layerType == 'map-image' && _layer && (_layer as __esri.MapImageLayer).sublayers && (_layer as __esri.MapImageLayer).sublayers.length > 0) {
      subLayer = (_layer as __esri.MapImageLayer).sublayers.find(l => l.id == parseInt(legend.id));
      attr = subLayer.get<LayerDataAttribute[]>("layerAttributes");
    } else if (legend.layerType == 'feature') {
      attr = _layer.get<LayerDataAttribute[]>("layerAttributes");
    }
    let subQuery = this.legendQuerySevice.getSubQuery(legend);
    let featureUrl: string = legend.layerType == 'map-image' ? subLayer.url : ((_layer as __esri.FeatureLayer).url + '/' + legend.id);
    let _url = featureUrl + "?token=" + this.configService.getGisToken();    
    var query: __esri.Query = new this.esriService.Query();
    query.returnGeometry = true;
    query.where = '1=1 ' + (subQuery ? (' and ' + subQuery) : '');
    query.outFields = this.getAttributes(attr)
    query.returnGeometry = true;
    var _self = this;
    let featureLayer: __esri.FeatureLayer = new _self.esriService.FeatureLayer();

    featureLayer.url = featureUrl.replace('MapServer', 'FeatureServer');

    featureLayer.outFields = _self.getAttributes(attr);
    featureLayer.popupTemplate = new _self.esriService.PopupTemplate();
    let result = await featureLayer.queryFeatures(query).then(res => {
      return res;
    })
    featureLayer.visible = false;
    //if (!!!legend.legendGUID && !!!legend.totalCount) {
      legend.totalCount = result.features.length;
    //}    
    let _set = {
      featureSet: result,
      featureLayer: featureLayer,
      subLayer: (subLayer ?? _layer as __esri.FeatureLayer),
      legend: legend
    }
    return _set;
  }

  public getAttributes(attributes: LayerDataAttribute[]): any[] {

    let result = [];
    let objAdded: boolean = false;
    attributes.filter(x => !x.name.toUpperCase().includes("SHAPE")).forEach(x => { // .filter(f => f.inMini)
      if (x.name.toUpperCase() == "OBJECTID") {
        objAdded = true;
      }
      result.push(x.name);
    })
    if (!objAdded) {
      result.push("OBJECTID");
    }
    return result;
  }

}


